var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[(_vm.item.Translate || _vm.item.Translate == undefined)?[_c('v-select',{staticClass:"pageElementLanguageSelectRichText mb-2",attrs:{"items":_vm.itemLanguages,"single-line":"","outlined":"","dense":"","hide-details":"auto"},model:{value:(_vm.itemDefaultLanguage),callback:function ($$v) {_vm.itemDefaultLanguage=$$v},expression:"itemDefaultLanguage"}})]:_vm._e(),_vm._l((_vm.item.Value),function(translatedValue,translatedValueIndex){return [(
          _vm.itemDefaultLanguage == translatedValueIndex &&
          ((!_vm.item.ReadOnly && !_vm.needsDisable) || _vm.editItemAdmin)
        )?[_c('editor',{key:_vm.tinyId + translatedValueIndex,attrs:{"api-key":"f18i0ywzo341k9tx3r3vjsnoghnomdrqbhx2qqi5lnul1dbh","required":_vm.item.Required,"init":{
            height: 500,
            menubar: false,
            language: _vm.editorLanguage,
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount',
            ],
            toolbar:
              'undo redo | formatselect | bold italic underline strikethrough | table image link | \
                       alignleft aligncenter alignright alignjustify | \
                       bullist numlist outdent indent | removeformat',
          },"disabled":_vm.$helpers.itemNeedsDisable(
              _vm.item.ReadOnly,
              _vm.needsDisable,
              _vm.editItemAdmin
            )},model:{value:(_vm.item.Value[translatedValueIndex]),callback:function ($$v) {_vm.$set(_vm.item.Value, translatedValueIndex, $$v)},expression:"item.Value[translatedValueIndex]"}})]:_vm._e(),(
          _vm.$helpers.itemNeedsDisable(
            _vm.item.ReadOnly,
            _vm.needsDisable,
            _vm.editItemAdmin
          ) && _vm.itemDefaultLanguage == translatedValueIndex
        )?_c('p',{key:_vm.tinyId + translatedValueIndex,staticClass:"mb-0 text-field-item",domProps:{"innerHTML":_vm._s(_vm.item.Value[translatedValueIndex])}}):_vm._e()]})],2),(_vm.$store.getters.editMode)?[_c('hr',{staticClass:"mt-5"}),_c('v-row',{staticClass:"my-5",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","lg":_vm.item.colWidth < 7
            ? _vm.item.colWidth < 5
              ? 12
              : 4
            : _vm.item.colWidth < 10
            ? 4
            : 2}},[_c('v-layout',{attrs:{"column":""}},[_c('v-text-field',{attrs:{"hide-details":"auto","type":"number","max":"12","min":"1","label":_vm.$t('Column width'),"outlined":"","dense":""},model:{value:(_vm.item.colWidth),callback:function ($$v) {_vm.$set(_vm.item, "colWidth", _vm._n($$v))},expression:"item.colWidth"}}),_c('v-switch',{staticClass:"float-left mr-3",attrs:{"flat":"","ripple":false,"hide-details":"auto","label":_vm.$t('Translate item')},model:{value:(_vm.item.Translate),callback:function ($$v) {_vm.$set(_vm.item, "Translate", $$v)},expression:"item.Translate"}}),_c('v-switch',{staticClass:"float-left mr-3",attrs:{"flat":"","ripple":false,"hide-details":"auto","label":_vm.$t('Field is required')},model:{value:(_vm.item.Required),callback:function ($$v) {_vm.$set(_vm.item, "Required", $$v)},expression:"item.Required"}}),_c('v-switch',{staticClass:"float-left mr-3",attrs:{"flat":"","ripple":false,"hide-details":"auto","label":_vm.$t('Read-only')},model:{value:(_vm.item.ReadOnly),callback:function ($$v) {_vm.$set(_vm.item, "ReadOnly", $$v)},expression:"item.ReadOnly"}}),_c('v-switch',{staticClass:"float-left mr-3",attrs:{"flat":"","ripple":false,"hide-details":"auto","label":_vm.$t('Remove frame')},model:{value:(_vm.item.RemoveCard),callback:function ($$v) {_vm.$set(_vm.item, "RemoveCard", $$v)},expression:"item.RemoveCard"}})],1)],1)],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }